<template>
  <div>
    <h1>{{ message }}</h1>
    <el-button type="primary" plain>Primary</el-button>
    <el-button :plain="true" @click="callParentMethod">Success</el-button>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    const open2 = inject("open2");

    const callParentMethod = () => {
      if (open2) {
        open2(); // 调用父组件的 open2 方法
      }
    };

    return {
      callParentMethod,
    };
  },
};
</script>
