<template>
  <div>
    <h1>{{ message }}</h1>
    <el-button type="primary" plain @click="text">hello world</el-button>
    <el-button :plain="true" @click="open2">Success</el-button>
    <Demo1View :message="parentMessage" />
  </div>
</template>

<script>
import { ref, provide } from "vue";
import { ElMessage } from "element-plus";
import Demo1View from "./Demo1View.vue";

export default {
  components: {
    Demo1View, // 注册子组件
  },
  setup() {
    const message = ref("hello");
    const parentMessage = ref(message);

    const open2 = () => {
      ElMessage({
        message: "Congrats, this is a success message.",
        type: "success",
      });
      message.value = "新的消息内容";
    };
    const text = () => {
      message.value = "hello world";
    };
    provide("open2", open2);

    return {
      message,
      open2,
      text,
      parentMessage,
    };
  },
};
</script>
